import { memo, useEffect } from 'react'
import type { FC } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { hex2Rgba, mq, vw } from '@/styles'
import { Figure } from './Figure'
import { iconByName } from '@/utils'
import { useSession } from '@/hooks'

export const LoadingScreen: FC<{ }> = memo(({ }) => {
  const { loaderActive:isActive } = useSession()

  useEffect(() => {
    document.body.style.overflow = (isActive ) ? 'hidden' : 'initial'
  }, [isActive])

  return (<Div className={(isActive ) ? 'active': ''}><Figure media={iconByName('loader-white')} className='loader'></Figure></Div>)
})

const Div = styled<any>(motion.div)`
  align-items: center;
  background-color: ${({ theme }) => hex2Rgba(theme.colors.neon_purple, .95)};
  bottom: 0;
  color: white;
  display: flex;
  height: 100dvh;
  height: 100vh;
  justify-content: center;
  left: 0;
  opacity:0 ;
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  transition: 150ms all ease-out;
  user-select: none;
  visibility: hidden;
  width: 100dvw;
  width: 100vw;
  z-index: -1;

  &.active {
    opacity:1 ;
    pointer-events: auto;
    position: fixed;
    right: 0;
    top: 0;
    transition: 150ms all ease-out;
    user-select: auto;
    visibility: visible;
    width: 100dvw;
    width: 100vw;
    z-index: 9999;
  }

  .loader {
    animation: loader .5s linear infinite;
    height: ${vw(100, 'mobile')};
    width: ${vw(100, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      height: ${vw(100, 'tablet')};
      width: ${vw(100, 'tablet')};
    }

    ${mq.greaterThan('tablet')} {
      height: ${vw(100, 'desktop')};
      width: ${vw(100, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      height: 100px;
      width: 100px;
    }

    @keyframes loader {
      0% {
        rotate: 0deg;
      }
      100% {
        rotate: 360deg;
      }
    }
  }
`
