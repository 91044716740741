'use client'
import { ReactNode, memo, useCallback, useMemo, useState } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { useDropdown } from './useDropdown'
import { Figure } from '../'
import { getP13_2 , getTransition, mq, vw, hideScrollbar } from '../../styles'
import { LOCALE } from '@/utils'

interface IDropdown {
  className?: string,
  trigger: ReactNode | string,
  options: any,
  direction: string
}

const dropDrownIcon = {
  type: 'svg' as const,
  src: '/images/svg/i--dropdown.svg',
  alt: 'dropdown icon',
  size: { width: 11, height: 5 }
}

const getOptions = (options, onClickOption, currentSelection) => {
  return options.map((option, idx) => (
    <li key={`${idx}key`} className={option.value === currentSelection?.toUpperCase() ? 'active' : ''}>
      <a href={`${option.path}`} onClick={onClickOption} >{option.value}</a>
    </li>
  ))
}

export const Dropdown = memo<IDropdown>(({ className = '', trigger, options, direction }) => {
  const [triggerRef, dropdownRef, expanded, toggleExpanded] = useDropdown()
  const [currentSelection, setSelection] = useState(LOCALE)

  const onClickOption = useCallback((option) => {
    toggleExpanded()
    setSelection(option.value)
  }, [toggleExpanded])

  const Options = useMemo(() => getOptions(options, onClickOption, currentSelection), [options, currentSelection, onClickOption])

  return (
    <DropdownWrapper className={className} $expanded={expanded} $direction={direction}>
      <button className='triggerButton' type='button' ref={triggerRef}  onClick={() => {toggleExpanded()}}>
        <span>{trigger}</span>
        <Figure className='dropdown' media={dropDrownIcon}/>
      </button>
      <OptionsList ref={dropdownRef} initial='collapsed' variants={optionsListVariants} animate={expanded ? 'expanded' : 'collapsed'}>
        {Options}
      </OptionsList>
    </DropdownWrapper>
  )
})


const DropdownWrapper = styled.div<any>`
  position: relative;
  right: 0;
  width: 45px;

  .triggerButton {
    ${getP13_2()}
    align-items: center;
    color: ${({ theme }) => theme.colors.white};
    display: flex;
    flex-direction: row;
    padding: 0;
    gap: 5px;
    span {
      font-size: 14px;
      color: ${({ theme }) => theme.colors.white};
      position: relative;
      width: 18px;

    }

    figure, .dropdown  {
      transform: ${({ $expanded, $direction }) => $expanded && $direction === 'down' ? 'rotate(-180deg)' : $direction === 'up' && !$expanded ? 'rotate(180deg)' : 'none'};
      height: 5px;
      width: 9px;
    }
  }

  ul {
    bottom: ${({ $direction }) => $direction === 'up' ? '200%' : 'unset'};
    top: ${({ $direction }) => $direction === 'down' ? '160%' : 'unset'};
  }
`

const OptionsList = styled(motion.ul) <any>`
  height: 0;
  opacity: 0;
  overflow-y: auto;
  position: absolute;
  width: auto;
  z-index: 10;
  background-color:#0c0550;
  padding: 15px;
  left: -8px;
  ${hideScrollbar()}

  li {
    color: ${({ theme }) => theme.colors.white};
    cursor: pointer;
    opacity: .4;
    width: 100%;
    transition: opacity 300ms ${({ theme }) => theme.ease}, background-color 300ms ${({ theme }) => theme.ease};
    ${getP13_2()}

    ${mq.lessThan('nexus7')}{
      margin-bottom: ${vw(15, 'mobile')};
    }

    ${mq.greaterThan('nexus7')}{
      margin-bottom: ${vw(15, 'mobile')};
    }

    ${mq.greaterThan('tablet')}{
      margin-bottom: ${vw(10, 'desktop')};
    }

    ${mq.greaterThan('container')}{
      margin-bottom: 10px;
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    &:active,
    &:hover,
    &.active {
      opacity: 1;
    }

    button {
      ${getP13_2()}
      color: ${({ theme }) => theme.colors.white};
    }
  }
`

const optionsListVariants = {
  collapsed: {
    height: '0',
    opacity: 0,
    transitionEnd: {
      display: 'none',
    },
    transition: { ...getTransition(.8) }
  },
  expanded: {
    height: 'auto',
    opacity: 1,
    display: 'block',
    transition: { ...getTransition(.8) }
  }
}
