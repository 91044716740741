'use client'
import { type FC, useMemo } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { MODALS } from '@/context'
import { useModal, useScrollTracker, useSession, useTranslator } from '@/hooks'
import { mq, vw, font15_15, font18_18 } from '@/styles'
import { Figure, Link } from '@/components'
import { iconByName } from '@/utils'
import { usePathname, useRouter, useParams } from 'next/navigation'
import {  useMount, useDeviceType  } from '@/hooks'
import { Dropdown } from '../dropdown'
import { LOCALE, stringToUppercase } from '@/utils'
import { getArrayOptions } from '../../components/layout/Footer'

const simpleLogo = {
  type: 'svg' as const,
  src: '/images/svg/logo-and-name.svg',
  alt: 'AiCam studio logo',
  size: { width: 150, height: 28 }
}

export const creditsIcon = {
  type: 'svg' as const,
  src: '/images/svg/i--credits.svg',
  alt: 'Icon credits',
  size: { width: 17, height: 17 }
}


export const Nav:FC = () => {
  const scrollState = useScrollTracker(70)
  const { isActive:isMenuActive, toggle:toggleMenu } = useModal(MODALS.MENU)
  const { user } = useSession()
  const { t } = useTranslator()
  const pathname = usePathname()
  const { push } = useRouter()
  const isMount  = useMount()
  const { isMobile } = useDeviceType()
  const isAccountPage = useMemo(() => pathname.includes('login') || pathname.includes('signup') || pathname.includes('forgot-password'), [pathname])
  const { lang } = useParams()

  return isMount &&(
    <NavStyled $scrollTop={scrollState === 'TOP'} $bgTransparent={false} $secondaryBar={false} $menuActive={isMenuActive} $isAccountPage={isAccountPage} initial={{ y: -60 }} animate={{ y: 0 }} transition={{ delay: 0, duration: .5, type: 'spring', damping: 10, stiffness: 50 }}>
      <ul>
        <Li className='li--menu'>
          <button onClick={toggleMenu} name='Menu'><Figure className={ 'menu'} media={iconByName('menu')} lazy={false} /></button>
        </Li>
        <Li key={'nav-logo'}>
          <Link href={`/${(lang === 'en' || !lang) ? '' : lang}`} className={ 'logo'}>
            <Figure media={simpleLogo} lazy={false} />
          </Link>
        </Li>
        <Li>
          {user ?
            <>
              {!pathname.includes('projects') && !isMobile && <Link href={`/${!lang ? 'en' : lang}/projects`}>{t('layout.nav.menu.your_projects') }</Link>}
              <div className='credits' onClick={() => { push(`/${!lang ? 'en' : lang}/credits`) }}>
                <Figure media={creditsIcon} lazy={false} />
                <span>{user?.credits}</span>
              </div>
            </>
            :
            <>
              {!isMobile &&
                <Dropdown className='detail-dropdown' trigger={stringToUppercase(lang ?? LOCALE)} options={getArrayOptions(pathname, lang)} direction='down' />
              }
              <Link href={`/${!lang ? 'en' : lang}/login`} className='try'> {t('layout.nav.login')}</Link>
            </>
          }
        </Li>
      </ul>
    </NavStyled>
  )
}

const NavStyled = styled(motion.nav) <{ $scrollTop: boolean, $bgTransparent: boolean, $secondaryBar: boolean, $menuActive: boolean, $isAccountPage: boolean }>`
  align-items: center;
  backdrop-filter: blur(40px);
  background-color:rgba(16,9,89,50%);
  border-bottom: ${({ $isAccountPage }) => !$isAccountPage ? '0.5px solid rgba(111,78,251,50%)' : 'none'};
  display: flex;
  height: 80px;
  justify-content: center;
  left: 0;
  margin: unset;
  max-height: 80px;
  position: fixed;
  top: 0;
  transform: translate3d(0, 0, 0);
  transition: 200ms box-shadow ease-in-out, 200ms border-color ease-in-out;
  width: 100%;
  z-index: 1000;


  ${mq.greaterThan('tablet')} {
    margin: 0 auto;
  }

  >ul {
    align-items: center;
    display: flex;
    grid-column: 1 / span 6;
    height: 100%;
    justify-content: space-between;
    margin: 0 auto;
    max-width: 95%;
    width: 95%;
    position: relative;

    ${mq.greaterThan('tablet')} {
      grid-column: 1 / span 12;
    }
    ${mq.greaterThan('desktop')} {
      max-width: 1440px;
    }
  }

  a {
    transition: 300ms color ease-in-out, 300ms opacity ease-in-out;

    &:hover {
      opacity: .7;
    }
  }
`

const Li = styled.li<any>`
  align-items: center;
  display: flex;
  height: 100%;
  font-size: 20px;

  &:first-child,
  &:nth-child(2),
  &:last-child {
    width: 100%;

    ${mq.greaterThan('nexus7')} {
      width:210px;
    }
  }
 &:nth-child(2){
  justify-content: center;
 }

  &:first-child {
    button {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;
    }

    figure {
      height: ${vw(10, 'mobile')};
      width: ${vw(30, 'mobile')};

      ${mq.greaterThan('nexus7')} {
        height: ${vw(10, 'tablet')};
        width: ${vw(30, 'tablet')};
      }

      ${mq.greaterThan('tablet')} {
        height: 10px;
        width: 30px;
      }

      &.back {
        height: ${vw(20, 'mobile')};
        width: ${vw(10, 'mobile')};

        ${mq.greaterThan('nexus7')} {
          height: ${vw(20, 'tablet')};
          width: ${vw(10, 'tablet')};
        }

        ${mq.greaterThan('tablet')} {
          height: ${vw(20, 'desktop')};
          width: ${vw(10, 'desktop')};
        }

        ${mq.greaterThan('desktop')} {
          height: 20px;
          width: 10px;
        }
      }
    }
  }

  &:last-child {
    gap: 20px;
    justify-content: flex-end;
    .credits {
      align-items: center;
      cursor: pointer;
      display: flex;
      font-size: 20px;
      justify-content: flex-end;
      letter-spacing: -.41px;
      opacity: 1;
      pointer-events: auto;
      transition: 300ms opacity ease;
      gap: 10px;

      &:hover {
        opacity: .7;
      }
      span {
        min-width: ${vw(26, 'mobile')};
        text-align: right;

        ${mq.greaterThan('nexus7')} {
          min-width: ${vw(26, 'tablet')};
        }

        ${mq.greaterThan('tablet')} {
          min-width: ${vw(26, 'desktop')};
        }

        ${mq.greaterThan('desktop')} {
          min-width: 26px;
        }
      }
    }

    figure {
      height: ${vw(17, 'mobile')};
      width: ${vw(17, 'mobile')};

      ${mq.greaterThan('nexus7')} {
        height: ${vw(17, 'tablet')};
        width: ${vw(17, 'tablet')};
      }

      ${mq.greaterThan('tablet')} {
        height: ${vw(17, 'desktop')};
        width: ${vw(17, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        height: 17px;
        width: 17px;
      }

    }
  }
  &.nav-links-desktop {
    gap: ${vw(40, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      gap: ${vw(40, 'tablet')};
    }

    ${mq.greaterThan('tablet')} {
      gap: ${vw(40, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      gap: 40px;
    }
  }

  .logo {
    align-items: center;
    display: flex;
    height: 100%;
    padding: 0 ${vw(20, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      padding: 0 ${vw(20, 'tablet')};
    }

    ${mq.greaterThan('tablet')} {
      padding: 0 ${vw(20, 'desktop')};
    }

    ${mq.greaterThan('desktop')} {
      padding: 0 20px;
    }

    figure {
      height: ${vw(28, 'mobile')};
      width: ${vw(150, 'mobile')};

      ${mq.greaterThan('nexus7')} {
        height: ${vw(28, 'tablet')};
        width: ${vw(150, 'tablet')};
      }

      ${mq.greaterThan('tablet')} {
        height: ${vw(28, 'desktop')};
        width: ${vw(150, 'desktop')};
      }

      ${mq.greaterThan('desktop')} {
        height: 28px;
        width: 150px;
      }
    }
    img {
      height: 100%;
      object-fit: contain;
      width: 100%;
    }
  }
  .try {
    ${font15_15(true, 600)}
    align-items: center;
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: ${vw(60, 'mobile')};
    color: ${({ theme }) => theme.colors.neon_purple};
    display: flex;
    gap: ${vw(10, 'mobile')};
    justify-content: center;
    padding:  ${vw(15, 'mobile')}  ${vw(17, 'mobile')};

    ${mq.greaterThan('nexus7')} {
      border-radius: ${vw(60, 'tablet')};
      gap: ${vw(10, 'tablet')};
      padding: ${vw(15, 'tablet')}  ${vw(17, 'tablet')};
    }

    ${mq.greaterThan('tablet')} {
      border-radius: ${vw(60, 'desktop')};
      gap: ${vw(10, 'desktop')};
      padding:  ${vw(15, 'desktop')}  ${vw(17, 'desktop')};
    }

    ${mq.greaterThan('tablet')} {
      ${font18_18(true, 600)}
    }

    ${mq.greaterThan('desktop-xs')} {
      ${font15_15(false, 600)}
    }

    ${mq.greaterThan('desktop')} {
      border-radius: 60px;
      gap: 10px;
      padding:  15px 17px ;
    }
  }

  .empty {
    display: none !important;
  }
`


