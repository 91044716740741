'use client'
import type { FC, ReactNode } from 'react'
import dynamic from 'next/dynamic'
import { ThemeProvider } from 'styled-components'
import StyledComponentsRegistry from '@/utils/styled-components-registry'
import { TranslationsProvider, ModalsProvider, SessionProvider, ImagesProvider, ToastProvider, NumberOfProcessingImagesProvider, TimerProvider } from '@/context'
import { theme } from '@/styles'
import { LoadingScreen } from './LoadingScreen'

const DynamicModalMenu = dynamic(() => import('@/components/modals/ModalMenu').then(mod => mod.ModalMenu), { ssr: false })
const DynamicModalAuthFeedback = dynamic(() => import('@/components/modals/ModalFeedBackAuth').then(mod => mod.ModalFeedBackAuth), { ssr: false })
const DynamicModalNewProject = dynamic(() => import('@/components/modals/ModalNewProject').then(mod => mod.ModalNewProject), { ssr: false })
const DynamicNumberImages = dynamic(() => import('@/components/modals/ModalNumberImages').then(mod => mod.ModalNumberImages), { ssr: false })
const DynamicDetail = dynamic(() => import('@/components/modals/ModalDetailAlbum').then(mod => mod.ModalDetailAlbum), { ssr: false })
const DynamicDelete = dynamic(() => import('@/components/modals/ModalDelete').then(mod => mod.ModalDelete), { ssr: false })

type Props = {
  children: ReactNode
}

const ClientProviders:FC<Props> = ({ children }) => {
  return (
    <StyledComponentsRegistry>
      <ThemeProvider theme={theme}>
        <TranslationsProvider>
          <SessionProvider>
            <ModalsProvider>
              <NumberOfProcessingImagesProvider>
                <TimerProvider>
                  <ImagesProvider>
                    <ToastProvider>
                      {children}
                      <DynamicModalMenu />
                      <DynamicModalAuthFeedback />
                      <DynamicModalNewProject />
                      <DynamicNumberImages />
                      <DynamicDetail />
                      <DynamicDelete />
                      <LoadingScreen/>
                    </ToastProvider>
                  </ImagesProvider>
                </TimerProvider>
              </NumberOfProcessingImagesProvider>
            </ModalsProvider>
          </SessionProvider>
        </TranslationsProvider>
      </ThemeProvider>
    </StyledComponentsRegistry>
  )
}

export default ClientProviders
